import React, { useMemo, useState } from 'react';

import { FormControlLabel } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';

import * as styles from './slider.module.less';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

interface Label {
  true: string;
  false: string;
}

interface FormProps {
  field: {
    name: string;
    value: string;
    onChange(): void;
  };
  setFieldValue: Function;
  label?: Label;
  classname;
}

export const SliderComponent = withStyles((theme: Theme) =>
  createStyles({
    switchBase: {
      padding: 1,
      marginLeft: -1,
      '&$checked': {
        transform: 'translateX(16px)',
        marginTop: -1,
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#4B90D9',
          opacity: 1,
          border: 'none',
        },
      },
      top: -1,
    },
    thumb: {
      width: 25,
      height: 25,
      border: '1px solid rgba(45,50,54, 0.4 )',
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
  }),
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      disableRipple
      classes={{
        root: styles.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const Slider: React.FC<FormProps> = ({ field: { onChange, name, value }, setFieldValue, label }: FormProps) => {
  const [state, setState] = useState(!!value);

  const handleChange = () => {
    setFieldValue(name, !state);
    setState(!state);
  };

  return (
    <FormControlLabel
      classes={{
        label: state ? styles.sliderLabelTrue : styles.sliderLabelFalse,
      }}
      control={<SliderComponent checked={!!value} onChange={handleChange} name={name} value={value} />}
      label={label ? label[String(state)] : ''}
    />
  );
};

export default Slider;
