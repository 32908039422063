import React from 'react';

import { useTranslation } from 'react-i18next';

import { AddMembershipState } from '../../utils/typedefs/membership';
import FormInputLabel from '../form-info-label/form-info-label';
import * as styles from './membership-discounts-info.module.less';

interface Props {
  values: AddMembershipState;
}

const MembershipDiscountInfo: React.FC<Props> = ({ values }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <FormInputLabel className={styles.itemA} label={t('renewals')}>
        {t(values.dates.renewals)}
      </FormInputLabel>

      <FormInputLabel className={styles.itemB} labelValueOverride={styles.labelValue} label={t('end date')}>
        {values.dates.endDate && t('dob format', { dob: values.dates.endDate })}
      </FormInputLabel>

      <FormInputLabel className={styles.itemC} labelValueOverride={styles.labelValue} label={t('start date')}>
        {values.dates.startDate && t('dob format', { dob: values.dates.startDate })}
      </FormInputLabel>

      <FormInputLabel className={styles.itemD} label={t('start date')}>
        {values.dates.gracePeriod}
      </FormInputLabel>
    </div>
  );
};

export default MembershipDiscountInfo;
